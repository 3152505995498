<template>
  <div>
    <Cycles
      id="cycle"
      :first="locale[lang].SPECIAL_V.FIRST"
      :images="images"
      :check="locale[lang].SPECIAL_V.CHECK"
      :semaine="locale[lang].SPECIAL_V.SEMAINE"
      :apres="locale[lang].SPECIAL_V.APRES"
      :exercices="locale[lang].SPECIAL_V.EXERCICES"
      :infoShop="locale[lang].SPECIAL_V.INFOSHOP"
    />
  </div>
</template>

<script>
import Cycles from "@/components/Cycles.vue";
export default {
  components: {
    Cycles,
  },
  data() {
    return {
      images: [
        "Cycles/SpecialV Imagen 120.png",
        "Cycles/SpecialV Imagen 121.png",
        "Cycles/SpecialV Imagen 122.png",
      ],
    };
  },
};
</script>

<style scoped>
  #cycle >>> #first .img-fluid {
    margin-bottom: 0;
  }
</style>
